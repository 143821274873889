import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const WigsForMenCancerPatient = () => {
  const seoData = {
    title: 'Wigs For Cancer Patients in Delhi - Radiance Hair Studio',
    description: 'Baldness due to chemotherapy? Radiance Hair Solution offers the most trusted wigs for cancer patients in Delhi. Visit us today',
    keywords: ['wigs for cancer patients in delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients1.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients2.png",
      "https://www.radiancehairstudio.com/help-image-1.png",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.php",
    "category": "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.php",
    "description": "Baldness due to chemotherapy? Radiance Hair Solution offers the most trusted wigs for cancer patients in Delhi. Visit us today.",
    "disambiguatingDescription": "Wigs for cancer patients in Delhi",
    "mainEntityOfPage": "Wigs For Cancer Patients",
    "serviceType": "Wigs For Cancer Patients in Delhi"
  };   

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/wigs-for-men-cancer-patients.png",
      content: (
        <div>
          <h2>Men's Wigs for Cancer Patients</h2>
          <p>
            Cancer patients are treated with Chemotherapies. Most of the time
            Chemo is provided as rays, but at times oral drugs are also used
            rigorously. In both cases, hair loss is quite obvious. To hide your
            baldness, resulting out of it, apply
            <strong> hair wigs in Delhi</strong>.
          </p>
          <h2>Regards from Radiance</h2>
          <p>
            Radiance Hair Studio will be treating your wig choice as a fashion
            element and will take care of many things, which you might or might
            not know. That is the key reason, while
            <strong> wigs for cancer patient</strong> are considered, why we are
            the choice of all.
          </p>
          <p>
            The first step in choosing the one for you would be chopping off the
            hair shortest. Consulting a hairdresser and acting accordingly comes
            next.
          </p>
          <p>
            Skin complexion of the patient,<a href="hair-extention-for-womens.php" style={{ color: "#373737"}}> hair loss</a> condition and many other
            things are checked by the professionals while choosing a wig for a
            cancer patient. Radiance is always dedicated to serving you with the
            best skills, and by delivering the needful knowledge to use the wigs.
            Thus determining the wig size, wig material, and everything is quite
            easy with us. Keeping the seriousness of cancer in our mind we use
            completely harmless products to attach wigs on the skull of the
            patient. We know that the patient might be affected by synthetic hair.
            That’s why we always use 100% natural human hair to design our wigs.
            And we treat every individual with special care.
          </p>
          <p>
            So, now a need is there to look into the benefits and questions that
            are often asked regarding the same, in order to remain acquainted with
            the guides.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/wigs-for-men-cancer-patients1.png",
      largeImg: "/wigs-for-men-cancer-patients2.png",
      content: (
        <div>
          <h2>Benefits of using hair wigs in Delhi</h2>
          <p></p>
          <ul>
            <li>
  
              For cancer patients, random hair loss is one of the external sign of
              the ailment and its treatment. Most of the time, the patients feel
              psychologically down for those things. The
              <strong class="rw4"> wigs for cancer patient</strong> can serve here
              perfectly.
            </li>
            <li>
  
              The wigs that you will be found with the different stores are
              perfect to be worn like a cap. Usually, these external things are to
              be removed during Chemo. So, you will remain hassle-free to open and
              re-wear the external hair, while choosing
              <strong> hair wigs in Delhi</strong>.
            </li>
            <li>
  
              Pricing is not at all a factor in case of these <a href="/wigs-for-men.php" style={{ color: "#373737"}}> wigs</a>. They are
              cheaper among all hair treatment plans. In fact, these are the
              cheapest among all sorts of hair wigs.
            </li>
            <li>
  
              The entire wig is an externally applied one and there remains no
              side-effect at all. One thing you must note here – remove the cap
              while taking chemo rays.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs about cancer patient's wigs",
      // description: "desc1",
      // description2: "desc 2",
      faq: [
        {
          title: "How easily I can wear the wigs?",
          description:
            "The one that we provide from Radiance is just like a cap. You can wear wigs for cancer patient yourself and can put it off too. There is no need for an expert from the next time, you wear it and for the first time, our professionals will show the style of wearing those in details.",
        },
        {
          title: "What is the length and material of the wig?",
          description:
            "One that we will be providing can be of any length, depending on your requirement. Material that we provide is only human hair. Since you are being treated with Chemo or radiation, synthetic might not be a good choice for you.",
        },
        {
          title: "How much is the cost?",
          description:
            "The wig is always the cheapest fashion element in case of external hair extension plans. This kind of wig is the cheapest among all types of wigs too. So, pricing will not be any constrained for you, while you reach Radiance for the purpose.",
        },
        {
          title: "Can it give me a natural look?",
          description:
            "Usually, we match the hair wigs in Delhi with your skin and complexion. In case of a cancer patient, skin complexion is darkened for the chemo used. Our professionals do everything to match the wig color with your skin condition so that you look the most natural.",
        }
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wigs For Cancer Patients in Delhi" banner="/wigs-for-cancer-patients-in-delhi-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Wigs For Cancer Patients" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default WigsForMenCancerPatient;
